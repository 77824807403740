import { TWILIO_CUSTOMER, TWILIO_ADDRESS, FAILURE_REASON } from '../../constants/ActionTypes'
const INIT_STATE = {
  customer:[],
  address:[],
  failureReason:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TWILIO_CUSTOMER: {
      return {...state, customer: action.payload};
    }
    case TWILIO_ADDRESS: {
        return {...state, address: action.payload};
    }
    case FAILURE_REASON: {
      return {...state, failureReason: action.payload};
    }
    default:
      return state;
  }
}
